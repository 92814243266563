@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.box {
    @include main-column;
}

.title {
    font-weight: 400;
    font-size: 30px;
    line-height: 1.1;
    width: 100%;
    margin: 0 0 20px;
    color: $title;
    
    & span {
        background-image: linear-gradient(110deg, #3a91e2 20%, #a741a6 70%, #e41485);
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        font-weight: 600;
    }

}

.container{
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    margin-top: 20px;
    position: relative;
}

.box_info{
    box-sizing: border-box;
}

.line {
    width: 100%;
    height: 1px;
    background: $title-box;
}

.text{
    @include text;
}

.image{
    width: 40%;
    height: auto;
    margin: 0 20px 0 0;
    float:left;
    border-radius: 16px
}


@media(max-width: 768px){

    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0;   
    }

    .title {
        text-align: center;
        font-size: 28px;
    }

    .image{
        width: 100%;
        margin: 0 0 20px;
    }
    
    .box_info{
        display: flex;
        flex-direction: column;
        align-items: center;  
    }
}


@media(max-width: 425px){
    .title {
        font-size: 26px;
    }
}