@import '../../base.scss';

.list {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

}

.item {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    width: auto;
    height: 100%;
}