@import '../../base.scss';

.main{
    @include main-pages;
}


.text{
    width: 100%;
    color: $text-gray;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    margin: 0;
    text-align: left;
    margin: 0 0 10px;
    padding: 0;
}

.box {
    @include main-column;
    max-width: 1440px;
    align-items: flex-start;
}

.subtitle {
    @include title;
    text-align: left;
}

.text_box {
    @include text;
}

.box_data{
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
    margin-top: 20px;
}

.data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.link_data{
    width: 100%;
    text-align: left;
    font-size: 20px;
    line-height: 1.25;
    font-weight: 700;
    margin:  0;
    color: $title;
    text-decoration: none;
    &:hover{
        opacity: .5;
        transition: .3s;
    }

    & span {
        font-weight: 400;
    }
}

@media(max-width: 768px){

    .text {
        margin: 20px auto 30px;
        font-size: 16px;
    }

    .box_data{
        flex-direction: column;
    }
}

@media(max-width: 425px){

}