@import '../../base.scss';

.main {
    box-sizing: border-box;
    max-width: 100vw;
    width: 100%;
    height: 90vh;
    overflow: hidden;
    background-image: url(../../image/InitialPage/img_4.jpg);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.overlay{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba($black, 0.3);
    color: $white;
}

.container{
    box-sizing: border-box;
    max-width: 850px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 4%;
    z-index: 1;
}

.title{
    width: 100%;
    font-size: 44px;
    line-height: 1.2;
    font-weight: 600;
    margin: 0;
    text-align: left;
    position: relative;
    text-transform: uppercase;
    color: $white;
}

//преимущества

.list {
    max-width: 700px;
    display: flex;
    align-items: flex-start;
    gap: 15px;
    margin-top: 30px;
}

.item {
    display: flex;
    align-items: flex-start;
    max-width: 220px;
    gap: 10px;
}

.check {
    color: #3a91e2;
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}

.item_text {
    font-size: 14px;
    line-height: 1.2;
    color: $white;
    font-weight: 400;
    margin: 0;

    & span {
        font-weight: 600;
    }
}
////////////////////////////

.box_button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px
}

.button {
    @include button;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}


@media(max-width: 1080px){
    .container{
        max-width: 750px;
        margin-left: 4%;
    }
    
    .title{
        font-size: 40px;
    }
}

@media(max-width: 900px){
    .container{
        max-width: 700px;
        margin-left: 4%;
    }
    
    .title{
        font-size: 36px;
    }

}

@media(max-width: 768px){

    .main {
        background-position: center;
    }

    .container{
        max-width: 100%;
        width: 100%;
        align-items: flex-start;
        margin-left: 0%;
        box-sizing: border-box;
        padding: 50px 20px 0;
    }

    .title{
        font-size: 32px;
    }

    .list {
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
    }

    .item {
        max-width: 100%;
    }

    .button {
        height: 40px;
    }
}

@media(max-width: 650px){

    .title{
        font-size: 30px;
    }
}

@media(max-width: 600px){

    .title{
        font-size: 26px;
}
}

@media(max-width: 520px){
    .container{
        padding: 0 10px 0;
    }

    .title{
        font-size: 22px;
}
}


@media(max-width: 430px){

    .title{
        font-size: 20px;
    }
}

@media(max-width: 390px){
    .title{
        font-size: 18px;
    }
}