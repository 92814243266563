@import '../../../base.scss';

.main{
    @include main-pages;
}

.box{
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    padding: 30px 20px;
}

.list_image {
    max-width: 50%;
    width: 100%;
    border-radius: 8px;
    aspect-ratio: 3/2;
    overflow: hidden;
}

.image {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    display: inline-block;
    object-fit: cover;
}

.box_info {
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0  0  0 20px;
}

.line {
    max-width: 100%;
    width: 100%;
    height: 1px;
    background: #3a91e2;
    margin: 10px auto;
}

.title {
    font-weight: 600;
    font-size: 26px;
    line-height: 1.2;
    width: 100%;
    margin: 0 0 5px;
    background-image: linear-gradient(110deg, #3a91e2 20%, #a741a6 70%, #e41485);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    margin: 0;
    color: $text;
}

.menu_link{
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    text-decoration: none;
    color: $text;

    &:hover{
        background-image: linear-gradient(110deg, #3a91e2 20%, #a741a6 70%, #e41485);
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        transition: 1s;
        cursor: pointer;
        transition: all .3s ease-out;
    }
}

.menu_text{
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    margin: 0;

    & span{
        color: $text;
    }
}

.button {
    @include button;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 40px;
}

.description {
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
}

.description_title {
    @include title;
}

.description_text {
    @include text;
}

@media(max-width: 900px){

    .box_info {
        padding: 0  0  0 10px;
    }
}

@media(max-width: 768px){
    .box{
        padding: 10px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .box_info {
        padding: 10px 0;
    }

    .list_image {
        max-width: 100%;
    }

    .button {
        max-width: 100%;
    }

    .description {
        padding: 10px;
    }
}

@media(max-width: 425px){
    .menu_link{
        font-size: 14px;
    }
    
    .menu_text{
        font-size: 14px;
    }
}