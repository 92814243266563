@import '../../base.scss';

    .main{
        @include main-column;
    }

    .box{
        box-sizing: border-box;
        max-width: 1440px;
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        overflow: hidden;
        padding: 80px 105px 100px;
        background-color:  rgba(#a741a6, 0.2);
        border-radius: 26px;
    }

    .box_text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        z-index: 1;
    }

    .title{
        font-weight: 400;
        font-size: 45px;
        line-height: 1;
        max-width: 650px;
        margin: 0;
        color: $title;
    }

    .text{
        max-width: 55%;
        width: 100%;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.2;
        color: $title;
        margin: 20px 0 0;
    }

    .button {
        @include button;
        margin-top: 20px;
    }

    .image {
        position: absolute;
        bottom: -60%;
        right: 0;
        width: 100%;
        height: auto;
    }

    @media(max-width: 1080px){

        .box{
            padding: 40px 80px 80px;
        }

        .title{
            font-size: 40px;
            max-width: 570px;
        }

        .text {
            max-width: 60%;
            font-size: 16px;
        }
    }

    @media(max-width: 900px){
        .image {
            position: absolute;
            bottom: -40%;
            right: 0;
            width: 100%;
            height: auto;
        }
    }

    @media(max-width: 768px){
        .box{
            padding: 40px 30px 80px;
        }

        .title{
            font-size: 35px;
            max-width: 500px;
        }

        .text {
            max-width: 100%;
            margin: 10px 0 0;
        }

        .image {
            position: absolute;
            bottom: -30%;
            right: 0;
            width: 100%;
            height: auto;
        }
    }

    @media(max-width: 580px){

        .title{
            font-size: 26px;
            max-width: 370px;
        }

        .image {
            bottom: -20%;
        }
    }

    @media(max-width: 425px){
        .image {
            bottom: -10%;
        }
    }