@import '../../base.scss';

.main{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 20px;
    background-color: $box;
    margin-bottom: 10px;
}

.box{
    max-width: 1400px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.menu_link{
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    text-decoration: none;
    color: $text;

    &:hover{
        background-image: linear-gradient(110deg, #3a91e2 20%, #a741a6 70%, #e41485);
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        transition: 1s;
        cursor: pointer;
        transition: all .3s ease-out;
    }
}

.menu_text{
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    margin: 0;
    background-image: linear-gradient(110deg, #3a91e2 20%, #a741a6 70%, #e41485);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;

    & span{
        color: $text;
    }
}

@media (max-width: 768px){
    .main{
        padding: 10px;
    }
}

@media(max-width: 425px){
    .menu_link{
        font-size: 14px;
    }
    
    .menu_text{
        font-size: 14px;
    }
}
