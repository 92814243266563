@import '../../base.scss';

.title{
    @include title;
    text-align: left;
}

.text{
    @include text;
}

.container_form{
    max-width: 1440px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    padding: 50px 20px 30px;
}

.form{
    max-width: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 50px;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 15px;
    background-color: rgba(#3a91e2, 0.2);
}

.box_input{
    display: flex;
    flex-direction: column;
    max-width: 420px;
    width: 100%;
    position: relative;
    height: 60px;
    background-color: $white;
    border-radius: 8px;
    padding: 0 25px;
    box-sizing: border-box;
    margin-top: 15px;

    &:nth-child(1){
        margin-top: 0;
    }
}

.label{
    width: 100%;
    color: $text-box;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    margin-top: 8px;
}

.input{
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background: none;
    border: none;
    outline: none;
    color: $text;
}

.box_textarea{
    display: flex;
    flex-direction: column;
    max-width: 420px;
    width: 100%;
    position: relative;
    height: 100px;
    background-color: $white;
    border-radius: 8px;
    padding: 0 25px 10px;
    box-sizing: border-box;
    margin-top: 15px;
}

.label_textarea{
    width: 100%;
    color: $text-box;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    margin-top: 8px;
    height: 80px;
    overflow: hidden;
}

.textarea{
    box-sizing: border-box;
    resize: none;
    width: 100%;
    min-height: 80px;
    background: none;
    border: none;
    outline: none;
    color: $text;
    margin-top: 5px;
}

.scrollbar {
    scrollbar-width: thin;
    scrollbar-color: $text-box #2F2F37;
}

.scrollbar::-webkit-scrollbar {
    width: 6px;
}

.scrollbar::-webkit-scrollbar-track {
    background-color: #2F2F37;
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: $text-box;
}

.wrong {
    color: $red;
}

.input_error{
    width: 100%;
    text-align: left;
    margin-top: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: $red;
    text-align: center;
}

.button_form{
    @include button;
    margin-top: 20px;
}

.button_disabled{
    @include button;
    margin-top: 20px;
    pointer-events: none;
    opacity: .5;
}

.text_form{
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    margin: 20px 0 0;
    width: 100%;
    text-align: left; 
    color: $text-box;
    max-width: 420px;
}

@media(max-width: 1080px){
    .box {
        max-width: 50%;
        width: 100%;
    }
}

@media(max-width: 768px){

    .container_form{
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
    }

    .box{
        max-width: 100%;
        width: 100%;
        align-items: center;
    }

    .form{
        max-width: 100%;
        margin: 0;
    }

    .box{
        width: 100%;
    }
    .box_image{
        display: none;
    }

    .container{
        padding: 0 10px;
    }

    .box_data{
        flex-direction: column;
        padding: 0 10px;
    }
}

@media(max-width: 425px){

    .form{
        padding: 20px 10px;
    }
}