@import '../../base.scss';

.header {
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $background;
    z-index: 5;
}

.header_scroll {
    background-color: $background;
    position: fixed;
    top: 0px;
    z-index: 10;
}

.box{
    box-sizing: border-box;
    max-width: 1440px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 20px 20px 10px;
}


.logo_link{
    box-sizing: content-box;
    display: flex;
    align-items: center;
}

.logo{
    width: 100px;
    height: auto;
}

.contacts{
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.icon {
    height: 18px;
    width: auto;
}

.phone{
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $title;
    text-decoration: none;
    white-space: nowrap;

    &:hover{
        opacity: .5;
        transition: .3s;
    }

    & span {
        font-weight: 700;
    }
}


.nav{
    padding: 0;
    max-width: 609px;
    width: 100%;
    gap: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style: none;
}

.link{
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    position: relative;
    text-decoration: none;
    transition: 1s;
    transition: all .3s ease-out;
    color: $dark-grey;

    &:hover{
        background-image: linear-gradient(110deg, #3a91e2 20%, #a741a6 70%, #e41485);
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        transition: 1s;
        cursor: pointer;
        transition: all .3s ease-out;
    }

    &::before{
        background: linear-gradient(110deg, #3a91e2 20%, #a741a6 70%, #e41485);
        position: absolute;
        content: '';
        bottom: -1px;
        width: 100%;
        height: 1px;
        transition: 1s;
        transform: scaleX(0);
    }
    &:hover:before{
        transform: scaleX(1);
    }
}

.button  {
    display: none;
}



@media(max-width: 900px){

    .box{
        padding: 10px 10px 20px;
        align-items: center;
    }

    .list{
        display: none;
    }

    .nav{
        display: none;
    }

    .logo{
        width: 70px;
        height: auto;
    }


    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        padding: 0;
        border: 0;
        background-image: url(../../image/Header/burger.svg);
        background-position: center;
        background-repeat: no-repeat;
        width: 32px;
        height: 20px;

        &:hover{
            cursor: pointer;
            filter: drop-shadow(0 0 5px $white);
            transition: all .3s ease-out;
        }
    }
}

@media(max-width: 425px){

    .box_button {
        display: none;
    }
    
    .icon {
        height: 20px;
        width: auto;
    }

    .logo{
        width: 60px;
        height: auto;
    }
}