@import '../../base.scss';

.main{
    @include main-column;
}

.list {
    margin-top: 10px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 20px;
}

.list_item_one {
    width: 100%;
    background-color: rgba(#a741a6, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba( $background-footer, .4 );
    overflow: hidden;
    aspect-ratio: 2/1;

    &:hover{
        cursor: pointer;
        box-shadow: 0 0 15px rgba( $background-footer, .4 );
        transform: scale(1.1);
        transition: .5s;
    }
}

.list_item {
    width: 100%;
    background-color: rgba(#3a91e2, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba( $background-footer, .4 );
    overflow: hidden;
    aspect-ratio: 2/1;

    &:hover{
        cursor: pointer;
        box-shadow: 0 0 15px rgba( $background-footer, .4 );
        transform: scale(1.1);
        transition: .5s;
    }
}


.item_box {
    width: 100%;
    height: 100%;
    position: relative;
    text-decoration: none;
    display: flex;
    justify-content: flex-end;
}

.box_text {
    box-sizing: border-box;
    padding: 20px 20px 0;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.text {
    margin: 5px 0 0;
    z-index: 1;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 500;
    text-align: center;
    color: $text;
    text-decoration: none;
    transition: all .3s ease-out;
}

.item_title {
    font-size: 21px;
    line-height: 1.2;
    font-weight: 700;
    margin: 0;
    color: $title;
    text-align: right;

}

.item_image{
    position: absolute;
    bottom: -40%;
    width: 100%;
    height: auto;
}

@media(max-width: 768px){
    .list {
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }
}

@media(max-width: 425px){
    .list {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    .item_title {
        font-size: 18px;
    }

    .text {
        font-size: 14px;
    }
}