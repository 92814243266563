@import '../../base.scss';

.main{
    @include main-pages;
}

.box{
    box-sizing: border-box;
    max-width: 1440px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    padding: 0 20px 20px;
}

.title{
    @include title;
}

.list {
    max-width: 100%;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 20px;
    margin-top: 20px;

}

////карточка

.item{
    position: relative;
    min-width: 230px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba( $background-footer, .4 );
    background: $white;
    overflow: hidden;
    transition: .5s;
    padding-bottom: 10px;

    &:hover{
        cursor: pointer;
        box-shadow: 0 0 15px rgba( $background-footer, .4 );
        transform: scale(1.1);
        transition: .5s;
    }
}

.link {
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.cell_image{
    width: 100%;
    border-radius: 8px;
}

.cell_info{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px 30px;
}

.cell_subtitle{
    color: $title;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
    margin: 0;
    text-transform: uppercase;
}

.cell_sort {
    color: $text;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    margin: 0;
}

.cell_arrow {
    width: 76px;
    height: 76px;
    background: linear-gradient(110deg, #3a91e2 20%, #a741a6 70%, #e41485);
    font-size: 22px;
    position: absolute;
    bottom: -30px;
    right: -30px;
    z-index: 2;
    border-radius: 50% 0 0 0;
    line-height: 68px;
    text-decoration: none;
    -webkit-transition: all ease 0.5s;
    -o-transition: all ease 0.5s;
    transition: all ease 0.5s;
    padding: 10px;
    box-sizing: border-box;
}

.arrow {
    width: 35px;
    height: auto;
    z-index: 10;
    margin: 0 20px 20px 0;
}

@media(max-width: 1280px){
    .list {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 20px;
    }
}

@media(max-width: 768px){
    .list {
        margin-top: 0;
    }

    .box{
        padding: 0 10px;
    }
}