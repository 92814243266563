@import '../../base.scss';

.main{
    @include main-column;
}

.box{
    box-sizing: border-box;
    max-width: 1400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title{
    @include title;
}

.list{
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: 1fr;
    margin-bottom: 30px;
}

.item{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 650px;
    overflow: hidden;
    aspect-ratio: 1/1;

    &:hover{
        transition: all .3s;
        cursor: pointer;
        filter: contrast(60%);
        
    }
}

.image{
    border-radius: 8px;
    width: 100%;
    height: 100%;
	vertical-align: middle;
	display: inline-block;
    object-fit: cover;
}

.button{
    @include button;
    max-width: 300px;
    width: 100%;
    height: 50px;
    border: 0;
}