@import '../../base.scss';

.main {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    right: 0;
    max-width: 320px;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: $box;
    padding: 25px 10px 25px 20px;
    z-index: 1000;
    transform: translateX(0px);
    transition: 0.7s;
}

.main_open {
    position: fixed;
    top: 0;
    right: 0;
    max-width: 320px;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $box;
    padding: 25px 10px 25px 20px;
    z-index: 1000;
    transform: translateX(500px);
    transition: 0.7s;
}

@media(max-width: 900px){

.main {
    visibility: visible;
    opacity: 1;
    }

.box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
}

.button {
    background-image: url(../../image/Header/close.svg);
    background-position: center;
    background-repeat: no-repeat;
    padding: 0;
    border: none;
    background-color: transparent;
    transition: .3s;
    width: 32px;
    height: 32px;

    &:hover{
        cursor: pointer;
        transform: rotate(180deg);
        transition: all .5s;
    }
}

.logo{
    width: 100px;
    height: auto;
}

.nav{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
}

.menu_link{
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    text-decoration: none;
    color: $title;
    position: relative;
    margin-top: 36px;
    transition: 0.5s;
    &:first-child {
        margin: 0;
    }
    &:hover{
        cursor: pointer;
        background-image: linear-gradient(110deg, #3a91e2 20%, #a741a6 70%, #e41485);
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        transition: 0.5s;
    }
}
}