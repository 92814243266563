@import '../../base.scss';

.main{
    @include main-column;
}

.box{
    box-sizing: border-box;
    max-width: 1440px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title{
    @include title;
}

.list {
    max-width: 100%;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.item {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    background: linear-gradient(180deg, #FEFEFE 0%, rgba(#3a91e2, 0.2) 100%);
    box-shadow: inset 0px 0px 50px rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(57px);
    border-radius: 15px;

}

.item_box {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 20px;

}

.item_number {
    font-weight: 800;
    font-size: 60px;
    line-height: 1;
    margin: 0;
    background-image: linear-gradient(110deg, #3a91e2 20%, #a741a6 70%, #e41485);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.item_title {
    color: $title;
    font-weight: 500;
    font-size: 23px;
    line-height: 1.2;
}

.item_text {
    color: $title;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
    margin: 0;

    & span {
        font-weight: 600;
    }

}

@media(max-width: 1280px){

    .list {
        grid-template-columns: repeat(auto-fit, minmax(405px, 1fr));
    }
}

@media(max-width: 768px){
    .item_number {
        font-size: 50px;
    }

    .item_title {
        font-size: 20px;
    }
    
    .item_text {
        font-size: 16px;    
    }
}

@media(max-width: 425px){
    .list {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 5px;
    }

    .item_title {
        font-size: 18px;
    }
    
    .item_text {
        font-size: 14px;    
    }
}