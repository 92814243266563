@import '../../base.scss';

.box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;
}

.box_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.type {
    font-weight: 500;
    margin: 0;
    font-size: 16px;
    line-height: 1.2;
    color: $title;
}

.subtitle {
    font-weight: 400;
    margin: 0;
    font-size: 16px;
    line-height: 1.2;
    color: $title;
}

.text {
    font-weight: 400;
    margin: 0;
    font-size: 14px;
    line-height: 1.2;
    color: $title;
}

@media(max-width: 410px){
    .type {
        font-size: 14px;
    }
    
    .subtitle {
        font-size: 14px;
    }
    
    .text {
        font-size: 12px;
    }
}

@media(max-width: 360px){
    .type {
        font-size: 12px;
    }
    
    .subtitle {
        font-size: 12px;
    }
    
    .text {
        font-size: 10px;
    }
}