@import '../../base.scss';

.main{
    @include main-pages;
}

.box{
    box-sizing: border-box;
    max-width: 1440px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    padding: 0 20px;
}

.title{
    @include title;
}

.list {
    max-width: 100%;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 30px;
    margin-top: 20px;

}

.item {
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
}

.image {
    width: 100%;
}

@media(max-width: 1280px){
    .list {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 20px;
    }
}

@media(max-width: 768px){
    .list {
        margin-top: 0;
    }

    .box{
        padding: 0 10px;
    }
}