@import '../../base.scss';

.main{
    width: 100vw;
    height: 100vh;
    background-color: $background;
    display: flex;
    align-items: center;
    justify-content: center;
    }

.box{
    margin: 0 auto;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title{
    font-size: 140px;
    line-height: 1.25;
    font-weight: 700;
    margin: 20px auto;
    color: #fffacd;
}

.subtitle{
    @include title;
    text-align: center;
    color: $text-box;
}

.text{
    @include text;
    margin: 5px auto 100px;
    color: $text-box;
    text-align: center;
    font-weight: 500
}

.link{
    @include text;
    text-decoration: none;
    color: #fffacd;
    margin: 0 auto;
    text-align: center;
    font-weight: 500;

    &:hover{
        cursor: pointer;
        opacity: 0.7;
        transition: all .5s ease-out;
    } 
}

@media (max-width: 768px){
    .subtitle{
        max-width: 400px;
    }

    .main{
        padding: 10px;
    }

}

@media (max-width: 425px){

    .subtitle{
        max-width: 300px;
    }

    .title{
        font-size: 80px;
    }
}