$white: #FFF;
$black: #252525;
$red:#CD2122;
$element: #84BC2D;

// фоны
$background: $white;
$background-footer: #242428;
$background-header: #1b1b1b;
$dark-grey: #292e32;
$box:  #eff3f4;


//техт
$text: #757582;
$text-gray: #939398;
$title: #1b3f5b;
$title-box: #e1dece;
$text-box: #d0d0d0;

/*button*/
$button-background: linear-gradient(110deg, #3a91e2 20%, #a741a6 70%, #e41485);
$button-color: $white;


@mixin main-column {
    max-width: 100%;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $white;
    padding: 30px 20px;

    @media(max-width: 768px){
        padding: 30px 10px;
    }

    @media(max-width: 425px){
        padding: 20px 10px;
    }
}

@mixin main-column-portfolio {
    max-width: 1440px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $white;
    padding: 0 20px 50px;
    overflow: hidden;

    @media(max-width: 768px){
        padding: 0 10px 30px;
    }
}

@mixin main-pages {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin container-column {
    max-width: 1440px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin container-row {
    max-width: 1440px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    padding: 0 20px;

    @media(max-width: 768px){
        padding: 0 10px;
    }
}

@mixin block-header {
    max-width: 1000px;
    color: $dark-grey;
    font-size: 52px;
    line-height: 1.25;
    font-weight: 700;
    margin: 0 0 10px;
    text-align: center;

    & span {
        color: $element;
    }

    @media(max-width: 1000px){
        max-width: 730px;
        font-size: 40px;
    }

    @media(max-width: 600px){
        max-width: 350px;
        font-size: 25px;
    } 
    
}


@mixin text {
    width: 100%;
    color: $text;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2;
    margin: 0;
    text-align: left;
    margin: 0 0 10px;
    
    @media(max-width: 768px){
        font-size: 16px;
    }
    
    @media(max-width: 425px){
        font-size: 16px;
    } 
}

@mixin title {
    font-weight: 400;
    font-size: 40px;
    line-height: 1.1;
    max-width: 1180px;
    width: 100%;
    text-align: center;
    margin: 0 0 20px;
    color: $title;

    @media(max-width: 1080px){
        font-size: 40px;
    }

    @media(max-width: 768px){
        font-size: 30px;
    }

    @media(max-width: 500px){
        font-size: 28px;
    }
    
    @media(max-width: 425px){
        font-size: 26px;
    }

    & span {
        background-image: linear-gradient(110deg, #3a91e2 20%, #a741a6 70%, #e41485);
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        font-weight: 600;
    }

}

@mixin button{
    text-transform: uppercase;
    background: $button-background;
    color: $white;
    border: none;
    max-width: 350px;
    width: 100%;
    height: 50px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    border-radius: 15px;
    box-shadow: 0 4px 0 #0e3458;
    transform: translateY(-5px);

    &:hover{
        cursor: pointer;
        transform: scale( .90 );
        transition: .5s;
        box-shadow: 0px 0px 2px 1px rgba(#e41485, 0.15), 0px 0px 4px 4px rgba(#e41485, 0.15);
    }
    
    @media(max-width: 425px){
        height: 40px;
        font-size: 14px;
    }
}

@mixin list{
    max-width: 1200px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 5px;

    @media(max-width: 768px){
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 10px;
        }
}

@mixin link {
    align-items: center;
    background-color: transparent;
    border: 1px solid $title-box;
    border-radius: 5px;
    color: $title-box;
    display: flex;
    font-size: 14px;
    text-transform: uppercase;
    height: 50px;
    justify-content: center;
    line-height: 1.2;
    text-decoration: none;
    transition: all .3s;
    width: 200px;
    transition: all .3s ease-out;

    &:hover {
        color: $background;
        background-color: $title-box;
        transition: all .3s ease-out;
    }
}